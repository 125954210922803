import { createSlice } from "@reduxjs/toolkit";

const initialState: { [k: string]: Barrier } = {};
export const barriersSlice = createSlice({
  name: "barriers",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = barriersSlice.actions;

export default barriersSlice.reducer;

export interface Barrier {
  type: "Inbound" | "Outbound";
  directionDisable: "None" | "Clockwise" | "CounterClockWise";
  mode: "Automatic" | "Manual";
  disableOnTrackClose: boolean;
  physicalPLC: string;
  forcible: boolean;
  trackPrefix: string;
  iconToMidpointBearing: number;
  passthrough: string[];
  icon: number[];
  midpoint: number[];
}
