import { createSlice } from "@reduxjs/toolkit";

const initialState: DirMax = {
//   HPG: { maximum: "", direction: "", status: "" },
//   AZ: { maximum: "", direction: "", status: "Open" },
//   SKID: { maximum: 1, direction: "", status: "Open" },
//   SLS: { maximum: 1, direction: "CW", status: "Open" },
//   RR: { maximum: 1, direction: "CW", status: "Open" },
//   ENTT: { maximum: 1, direction: "CCW", status: "Open" },
//   CTC: { maximum: 1, direction: "CW", status: "Open" },
//   CS: { maximum: 1, direction: "CW", status: "Open" },
//   CRT: { maximum: 1, direction: "CW", status: "Open" },
//   COT: { maximum: 1, direction: "CCW", status: "Open" },
//   DBT: { maximum: 1, direction: "CW", status: "Open" },
//   HST: { maximum: 2, direction: "CW", status: "Open" },
//   GR: { maximum: 1, direction: "CW", status: "Open" },
//   HT1: { maximum: 1, direction: "CCW", status: "Open" },
//   HT2: { maximum: 1, direction: "CCW", status: "Open" },
//   CT: { maximum: 1, direction: "CCW", status: "Open" },
//   "B/H": { maximum: 1, direction: "", status: "Open" },
};

export const dirMaxSlice = createSlice({
  name: "dirmax",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = dirMaxSlice.actions;

export default dirMaxSlice.reducer;

export interface DirMax {
  [k: string]: DirMaxEntry;
}

export interface DirMaxEntry {
  maximum: number | "";
  direction: "CCW" | "CW" | "";
  status: "Open" | "Closed" | "";
}
