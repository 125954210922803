import { createSlice } from "@reduxjs/toolkit";

const initialState: { [k: string]: PlcBarriersEvent } = {
};

export const plcBarriersEventSlice = createSlice({
  name: "plc-barriers-event",
  initialState: initialState,
  reducers: {
    update: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = plcBarriersEventSlice.actions;

export default plcBarriersEventSlice.reducer;

export interface PlcBarriersEvent {
  name: string;
  type: "Barrier";
  state: string | null;
  pendstate: string | null;
  disabled: string | number | null;
}
