import { createSlice } from "@reduxjs/toolkit";
import getConfig from "../../config/config";

const config = getConfig();

export const headerSlice = createSlice({
  name: "currentTrack",
  initialState: config.sitePrefix,
  reducers: {
    updateTrack: (state, data) => {
      state = data.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTrack } = headerSlice.actions;

export default headerSlice.reducer;
